'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from 'mxcn';
const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
function TooltipContent({
  className,
  sideOffset = 4,
  ref,
  ...props
}: React.ComponentPropsWithRef<typeof TooltipPrimitive.Content>) {
  return <TooltipPrimitive.Portal data-sentry-element="unknown" data-sentry-component="TooltipContent" data-sentry-source-file="tooltip.tsx">
      <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn('z-50 overflow-hidden rounded-md bg-gray-900 px-3 py-1.5 text-xs text-gray-50 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:bg-gray-50 dark:text-gray-900', className)} {...props} data-sentry-element="unknown" data-sentry-source-file="tooltip.tsx" />
    </TooltipPrimitive.Portal>;
}
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
export function SimpleTooltip(props: {
  label: string;
  children: React.ReactNode;
}) {
  return <Tooltip data-sentry-element="Tooltip" data-sentry-component="SimpleTooltip" data-sentry-source-file="tooltip.tsx">
      <TooltipTrigger data-sentry-element="TooltipTrigger" data-sentry-source-file="tooltip.tsx">{props.children}</TooltipTrigger>
      <TooltipContent data-sentry-element="TooltipContent" data-sentry-source-file="tooltip.tsx">{props.label}</TooltipContent>
    </Tooltip>;
}
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };