const grafana = {
  openTelemetry: {
    protocol: process.env.OTEL_EXPORTER_OTLP_PROTOCOL!,
    endpoint: process.env.OTEL_EXPORTER_OTLP_ENDPOINT!,
    headers: process.env.OTEL_EXPORTER_OTLP_HEADERS!,
  },
  influx: {
    url: process.env.GRAFANA_INFLUX_URL!,
    user: process.env.GRAFANA_INFLUX_USER!,
    key: process.env.GRAFANA_INFLUX_KEY!,
  },
};

export default grafana;
