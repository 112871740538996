import environment, { getEnvVariable } from './environment';

const mocks = {
  get url() {
    return getEnvVariable('MOCK_SERVER_URL');
  },
  oauth: {
    enabled: environment.isLocal,
  },
};

export default mocks;
