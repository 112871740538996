'use client';

import { useEffect } from 'react';
import config from '@/config';
import { HydrationData } from './-jotai';
import { identifyUser } from '@/lib/track-event';
const IntercomProvider = ({
  user
}: HydrationData) => {
  useEffect(() => {
    if (config.environment.isProduction && user && window.Intercom) {
      identifyUser(user);
    }
  }, [user]);
  return null;
};
export default IntercomProvider;