'use client';

import * as React from 'react';
import { cn } from 'mxcn';
import { useFieldMaybe } from './field/use-field';
function Textarea({
  className,
  onValueChange,
  onBlur,
  ref,
  ...props
}: React.ComponentPropsWithRef<'textarea'> & {
  onValueChange?: (value: string) => void;
}) {
  const field = useFieldMaybe<string>();
  if (field && !('value' in props)) {
    props.value = field.value;
    props.id ??= field.id;
    const originalOnValueChange = onValueChange;
    onValueChange = value => {
      field.onValueChange(value);
      originalOnValueChange?.(value);
    };
    const originalOnBlur = onBlur;
    onBlur = e => {
      field.onBlur();
      originalOnBlur?.(e);
    };
  }
  props.value ??= '';
  return <textarea className={cn('flex min-h-[60px] w-full rounded-md border border-contrast-200 bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-contrast-300 focus-visible:outline-none focus-visible:ring-2 ring-focus-500/30 focus-visible:border-focus-500 disabled:cursor-not-allowed disabled:opacity-50', className)} ref={ref} onInput={e => onValueChange?.((e.target as HTMLInputElement).value)} onBlur={e => {
    onBlur?.(e);
  }} aria-describedby={field && (field.localErrors.length > 0 || field.serverErrors.length > 0) ? field?.id + '-errors' : undefined} {...props} data-sentry-component="Textarea" data-sentry-source-file="textarea.tsx" />;
}
export { Textarea };