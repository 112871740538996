'use client';

import { ComponentPropsWithoutRef } from 'react';
import Field from './field';
import { Label } from '../label';
import cn from 'mxcn';
import FieldError from './field-error';
import { sentenceCase } from 'change-case';
export default function StandardField<T>({
  children,
  hideLabel,
  ...props
}: ComponentPropsWithoutRef<typeof Field<T>> & {
  label?: React.ReactNode;
  hideLabel?: boolean;
  description?: React.ReactNode;
}) {
  return <Field {...props} data-sentry-element="Field" data-sentry-component="StandardField" data-sentry-source-file="standard-field.tsx">
      {({
      field
    }) => <>
          <div className={cn('stack-1 relative py-1', hideLabel && !props.description && 'absolute')}>
            <Label className={cn('font-medium text-contrast-700 leading-snug cursor-[inherit] inline-flex', hideLabel && 'sr-only absolute')}>
              {props.label ?? sentenceCase(field.name)}
              <FieldError />
            </Label>
            {props.description && <div className="text-sm text-muted">{props.description}</div>}
          </div>
          {typeof children === 'function' ? children({
        field
      }) : children}
        </>}
    </Field>;
}