'use client';

import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'mxcn';
import { useFieldMaybe } from './field/use-field';
import { sentenceCase } from 'change-case';
export const labelVariants = cva('text-sm font-medium leading-none text-muted peer-disabled:cursor-not-allowed peer-disabled:opacity-70');
function Label({
  className,
  ref,
  ...props
}: React.ComponentPropsWithRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>) {
  const field = useFieldMaybe();
  if (field) {
    props.htmlFor ??= field.id;
    props.children ??= <>{sentenceCase(field.name)}</>;
  }
  return <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} data-sentry-element="unknown" data-sentry-component="Label" data-sentry-source-file="label.tsx" />;
}
export { Label };