import { getEnvVariable } from './environment';

const logDrainConfig = {
  vercel: {
    get verification() {
      return getEnvVariable('LOG_DRAIN_VERIFICATION');
    },
    get sharedSecretKey() {
      return getEnvVariable('LOG_DRAIN_SECRET');
    },
    get gitCommitHash() {
      return process.env.VERCEL_GIT_COMMIT_SHA ?? '';
    },
  },
  loki: {
    get url() {
      return getEnvVariable('GRAFANA_LOKI_URL');
    },
    get user() {
      return getEnvVariable('GRAFANA_LOKI_USER');
    },
    get key() {
      return getEnvVariable('GRAFANA_LOKI_KEY');
    },
  },
};
export default logDrainConfig;
