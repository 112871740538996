import { trackEvent as intercomTrackEvent } from '@intercom/messenger-js-sdk';
import { User } from '@prisma/client';
import posthog, { Properties } from 'posthog-js';
import config from '@/config';

/**
 * Tracks an event using both PostHog and Intercom.
 *
 * @param category - The category of the event to be tracked.
 * @param metadata - Additional properties or metadata associated with the event.
 */
export const trackEvent = (category: string, metadata: Properties) => {
  posthog.capture(category, metadata);
  intercomTrackEvent(category, metadata);
};

/**
 * Identifies a user in PostHog and Intercom.
 *
 * @param user - The user to be identified.
 */
export const identifyUser = (user: User) => {
  const userInfo = {
    email: user.email ?? '',
    phone: user.phoneNumber ?? '',
    name: `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
  };
  posthog.identify(user.id, userInfo);

  const Intercom = window.Intercom!;
  Intercom('update', {
    user_id: user.legacyUid ?? user.id,
    ...userInfo,
  });
};

/**
 * Resets the user in PostHog and Intercom.
 */
export const resetUser = () => {
  posthog.reset(true); //reset device_id

  if (config.environment.isProduction && window.Intercom) {
    const Intercom = window.Intercom;
    Intercom('update', {
      email: null,
      phone: null,
      name: null,
    });
  }
};
