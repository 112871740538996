import { User } from '@prisma/client';
import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactNode } from 'react';
import { userAtom } from './-atoms';
export type HydrationData = {
  user: User | null;
};
export default function GlobalJotaiProvider({
  children,
  ...hydrationData
}: HydrationData & {
  children: ReactNode;
}) {
  return <Provider data-sentry-element="Provider" data-sentry-component="GlobalJotaiProvider" data-sentry-source-file="-jotai.tsx">
      <HydrateGlobalJotaiAtoms {...hydrationData} data-sentry-element="HydrateGlobalJotaiAtoms" data-sentry-source-file="-jotai.tsx" />
      {children}
    </Provider>;
}
function HydrateGlobalJotaiAtoms(props: HydrationData) {
  useHydrateAtoms([[userAtom, props.user]]);
  return null;
}