import { AnimatePresence, motion } from 'motion/react';
import cn from 'mxcn';
import { useField } from './use-field';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../hover-card';
export default function FieldError(props: {
  className?: string;
}) {
  const field = useField();
  let errors = field.serverErrors;
  if (field.blurred && field.localErrors.length > 0) {
    errors = errors.concat(field.localErrors);
  }
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="FieldError" data-sentry-source-file="field-error.tsx">
      {field.blurred && errors.length > 0 && <motion.div animate={{
      x: 0,
      opacity: 1
    }} initial={{
      x: 20,
      opacity: 0
    }} id={field.id + '-errors'} role="alert" className={cn('inline-flex self-center mx-2', props.className)}>
          <div className="relative size-3">
            <HoverCard openDelay={0} closeDelay={400}>
              <HoverCardTrigger className="absolute -inset-2 cursor-pointer">
                <div className="absolute inset-2 rounded-full size-3 bg-blue-400 animate-ping" />
                <div className="absolute inset-2.5 rounded-full size-2 bg-blue-400" />
              </HoverCardTrigger>

              <HoverCardContent side="top" className="bg-contrast-800 text-contrast-50 stack px-2 py-1 w-max ">
                {errors.map((e, i) => <div key={i}>{e.message}</div>)}
              </HoverCardContent>
            </HoverCard>
            <div className="sr-only">
              {errors.map((e, i) => <div key={i}>{e.message}</div>)}
            </div>
          </div>
        </motion.div>}
    </AnimatePresence>;
}