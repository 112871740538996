import analytics from './analytics';
import environment from './environment';
import files from './files';
import intercomConfig from './intercom';
import llm from './llm';
import logging from './logging';
import mocks from './mocks';
import grafana from './grafana';
import logDrainConfig from './log-drain';

const config = {
  analytics,
  environment,
  files,
  llm,
  logging,
  mocks,
  grafana,
  intercomConfig,
  logDrainConfig,
};

export default config;
